// src/components/Header.js
import React from 'react';
import './Header.css';
import background from '../images/background.jpg';
import logo from '../images/logo.png';
import palloliitto from '../images/palloliitto.png';
import jaapalloliitto from '../images/jaapalloliitto.png';

const Header = () => {
  return (
    <header className="header">
      <div className="header-background">
        <img src={background} alt="Background" className="background-img" />
        <border className="background-border"></border>
      </div>
      <div className="header-content">
        <img src={logo} alt="JPS logo" className="main-logo" />
        <div className="additional-logos">
          <img src={jaapalloliitto} alt="Suomen jääpalloliitto" className="small-logo jaapalloliitto" />
          <img src={palloliitto} alt="Suomen palloliitto" className="small-logo palloliitto" />
        </div>
      </div>
    </header>
  );
};

export default Header;
