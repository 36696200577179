// src/components/Info.js
import React from 'react';
import './Info.css';
import info from '../images/info.jpg';

const Info = () => {
  const handleContactClick = () => {
    // Navigointi yhteystieto-osioon, esim. scrollaus
    const contactSection = document.getElementById('contacts');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="info">
      <div className="info-image">
        <img src={info} alt="Info" />
      </div>
      <div className="info-content">
        <h1>JPS - bandyliigan mestari 2025</h1>
        <h1>TERVETULOA</h1>
        <p>Olet saapunut Jyväskylän Seudun Palloseura Ryn ja JPS Juniorit Ryn yhteisille verkkosivuille. Uudistamme parhaillaan kotisivujamme, mutta löydät kaikki ajankohtaiset tiedot ja uutiset seuran sosiaalisen median kanavista tai ottamalla yhteyttä alla oleviin henkilöihin.</p>
      </div>
    </section>
  );
};

export default Info;
