import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaPhone, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import './Contacts.css';

import Renta from '../images/sponsors/renta.png';
import Pihlavamäki from '../images/sponsors/pihlavamaki.png';
import Raikua from '../images/sponsors/raikua.png';
import Tana from '../images/sponsors/tana.png';
import SEOpalvelu from '../images/sponsors/seopalvelu.png';
import CRM from '../images/sponsors/crm.png';

const clubContacts = [
  {
    club: "Jyväskylän Seudun Palloseura Ry",
    // sponsors: [Renta, SEOpalvelu],
    socialLinks: [
      {
        platform: "Instagram",
        icon: <FaInstagram />,
        handle: "@jpsbandy",
        url: "https://www.instagram.com/jpsbandy"
      },
      {
        platform: "Facebook",
        icon: <FaFacebook />,
        handle: "JPS Bandy Official",
        url: "https://www.facebook.com/JPSBANDY?rdid=i4r2q1q7s3UYNJpa&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F19SDf8gkr3%2F#"
      },
      {
        platform: "Instagram",
        icon: <FaInstagram />,
        handle: "@jpsfutis",
        url: "https://www.instagram.com/jpsfutis"
      },
      
    ],
    categories: [
      {
        category: "Johtokunta",
        roles: [
          {
            role: "Sihteeri",
            name: "Karri Hiltunen",
            email: "karri.hiltunen@edu.jyvaskyla.fi",
            phone: "+358 405535305"
          },
        ]
      },
      {
        category: "Bandyliiga",
        roles: [
          {
            role: "Joukkueenjohtaja",
            name: "Jarkko Lampinen",
            email: "jlampine@gmail.com",
            phone: "+358 405535305"
          }
        ]
      },
      {
        category: "Nuorten bandyliiga",
        roles: [
        {
          role: "PÄIVITETÄÄN MYÖHEMMIN",
          name: "",
          email: "-",
          phone: "+358 405535305"
        },
       ],
      },
      {
        category: "Jalkapallo",
        roles: [
          {
            role: "Päävalmentaja",
            name: "Niko Rautionaho",
            email: "nrautionaho@gmail.com",
            phone: "???"
          },
          {
            role: "Valmentaja",
            name: "Lenni Purokuru",
            email: "lenni-98@hotmail.com",
            phone: "???"
          },
          {
            role: "Joukkueenjohtaja",
            name: "Joakim Peltoniemi",
            email: "joakim.peltoniemi@gmail.com",
            phone: "+358 401234567"
          },
        ],
      }
    ]
  },

  {
    club: "JPS Juniorit Ry",
    sponsors: [Renta, Pihlavamäki, Raikua, Tana],
    socialLinks: [
      {
        platform: "Facebook",
        icon: <FaFacebook />,
        handle: "JPS-juniorit bandy",
        url: "https://www.facebook.com/profile.php?id=100057481688582"
      },
      {
        platform: "Instagram",
        icon: <FaInstagram />,
        handle: "@jpsjunioritbandy",
        url: "https://www.instagram.com/jpsjunioritbandy"
      },
      {
        platform: "Instagram",
        icon: <FaInstagram />,
        handle: "@jpsjunioritfutis",
        url: "https://www.facebook.com/jpsjunioritfutis"
      }
    ],
    categories: [
      {
        category: "Johtokunta",
        roles: [
          {
            role: "Puheenjohtaja",
            name: "Arto Javanainen",
            email: "junioritjps@gmail.com",
            phone: "???"
          },
          {
            role: "Sihteeri",
            name: "Hanna Pitkänen",
            email: "-",
            phone: "???"
          },
          {
            role: "Rahastonhoitaja",
            name: "Jenni Hovinen",
            email: "-",
            phone: "???"
          },
          {
            role: "Varapuheenjohtaja",
            name: "Mikko Böhm",
            email: "-",
            phone: "???"
          },
          {
            role: "Jäsen",
            name: "Fabio Yari",
            email: "-",
            phone: "???"
          },
        ]
      },
      {
        category: "Jääpallo",
        teams: [
          {
            team: "Pojat U16",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Otto Virkkula",
                email: "ottoveli@gmail.com",
                phone: "???"
              }
            ]
          },
          {
            team: "Pojat U14",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Otto Virkkula",
                email: "ottoveli@gmail.com",
                phone: "???"
              }
            ]
          },
          {
            team: "Pojat U12",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Samuli Pentinniemi",
                email: "samuli.pentinniemi@gmail.com",
                phone: "???"
              }
            ]
          },
          {
            team: "Pojat U10",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Antti Laitinen",
                email: "laitinen.anttijussi@gmail.com",
                phone: "???"
              }
            ]
          },
          {
            team: "Jääpallokoulu",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Arto Javanainen",
                email: "junioritjps@gmail.com",
                phone: "???"
              }
            ]
          },
        ]
      },
      {
        category: "Jalkapallo",
        teams: [
          {
            team: "Pojat 14v",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Pirkka Lahtinen",
                email: "pirkka.p.lahtinen@gmail.com",
                phone: "???"
              }
            ]
          },
          {
            team: "Pojat 13v",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Mikko Böhm",
                email: "bohm.mikko@gmail.com",
                phone: "???"
              }
            ]
          },
          {
            team: "Pojat 12v",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Juha Varis",
                email: "juhavaris83@gmail.com",
                phone: "???"
              }
            ]
          },
          {
            team: "Pojat 10v",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Antti Laitinen",
                email: "laitinen.anttijussi@gmail.com",
                phone: "???"
              }
            ]
          },
          {
            team: "Pojat 8v",
            sponsors: [SEOpalvelu, CRM],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Osmo Hiltunen",
                email: "osmohilt@gmail.com",
                phone: "???"
              }
            ]
          },
          {
            team: "Pojat 7v",
            sponsors: [],
            roles: [
              {
                role: "Joukkueenjohtaja",
                name: "Anssi Hänninen",
                email: "anssihanninen@gmail.com",
                phone: "???"
              }
            ]
          },
        ]
      },
    ]
  }
];

const Contacts = () => {
  const [openClubIndex, setOpenClubIndex] = useState(null);
  const [openCategoryIndex, setOpenCategoryIndex] = useState({});
  const [openTeams, setOpenTeams] = useState({});

  useEffect(() => {
    const slides = document.querySelectorAll(".club-sponsors-slide");
    slides.forEach((slide) => {
      const copy = slide.cloneNode(true);
      slide.parentNode.appendChild(copy);
    });
  }, []);

  const toggleClubAccordion = (clubIndex) => {
    setOpenClubIndex(openClubIndex === clubIndex ? null : clubIndex);
    setOpenCategoryIndex({});
    setOpenTeams({});
  };

  const toggleCategoryAccordion = (clubIndex, categoryIndex) => {
    setOpenCategoryIndex((prev) => ({
      ...prev,
      [clubIndex]: prev[clubIndex] === categoryIndex ? null : categoryIndex
    }));
    setOpenTeams({});
  };

  const toggleTeamAccordion = (clubIndex, categoryIndex, teamIndex) => {
    setOpenTeams((prev) => ({
      ...prev,
      [`${clubIndex}-${categoryIndex}`]: prev[`${clubIndex}-${categoryIndex}`] === teamIndex ? null : teamIndex
    }));
  };

  return (
    <section className="contacts" id="contacts">
      <div className="contacts-text">
        <h2>YHTEYSTIEDOT</h2>
        <p>Täältä löydät seurojen, joukkueiden ja avainhenkilöiden yhteystiedot.</p>
      </div>
  
      <div className="accordion-container">
        {clubContacts.map((club, clubIndex) => (
          <div key={clubIndex} className="accordion-item">
            <div className="club-header">
              <button
                className="accordion-clubheader"
                onClick={() => toggleClubAccordion(clubIndex)}
              >
                <h3>{club.club}</h3>
                {openClubIndex === clubIndex ? <FaChevronUp /> : <FaChevronDown />}
              </button>
            </div>
            {openClubIndex === clubIndex && (
              <div className="accordion-content">
                {club.categories.map((category, categoryIndex) => (
                  <div key={categoryIndex} className="accordion-item">
                    <button
                      className="accordion-teamheader sport-header"
                      onClick={() => toggleCategoryAccordion(clubIndex, categoryIndex)}
                    >
                      <h4>{category.category}</h4>
                      {openCategoryIndex[clubIndex] === categoryIndex ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronDown />
                      )}
                    </button>
                    {openCategoryIndex[clubIndex] === categoryIndex && (
                      <div className="accordion-content sport-content">
                        {category.roles ? (
                          category.roles.map((role, roleIndex) => (
                            <div key={roleIndex} className="role">
                              <p>
                                <strong>{role.role}: {role.name}</strong>
                              </p>
                              <p>
                                <FaEnvelope />{" "}
                                <a href={`mailto:${role.email}`}>{role.email}</a>
                              </p>
                              {/* <p>
                                <FaPhone />{" "}
                                <a href={`tel:${role.phone}`}>{role.phone}</a>
                              </p> */}
                              <br />
                            </div>
                          ))
                        ) : (
                          category.teams.map((team, teamIndex) => (
                            <div key={teamIndex} className="accordion-item">
                              <button
                                className="accordion-teamheader team-header"
                                onClick={() =>
                                  toggleTeamAccordion(clubIndex, categoryIndex, teamIndex)
                                }
                              >
                                <h4>{team.team}</h4>
                                {openTeams[`${clubIndex}-${categoryIndex}`] === teamIndex ? (
                                  <FaChevronUp />
                                ) : (
                                  <FaChevronDown />
                                )}
                              </button>
                              {openTeams[`${clubIndex}-${categoryIndex}`] === teamIndex && (
                                <div className="accordion-teamcontent">
                                  {team.roles.map((role, roleIndex) => (
                                    <div key={roleIndex} className="role">
                                      <p>
                                        <strong>{role.role}: {role.name}</strong>
                                      </p>
                                      <p>
                                        <FaEnvelope />{" "}
                                        <a href={`mailto:${role.email}`}>{role.email}</a>
                                      </p>
                                      {/* <p>
                                        <FaPhone />{" "}
                                        <a href={`tel:${role.phone}`}>{role.phone}</a>
                                      </p> */}
                                      <br />
                                    </div>
                                  ))}
                                  {team.sponsors && team.sponsors.length > 0 && (
                                    <div className="team-sponsors">
                                      <h1>Joukkueen sponsorit</h1>
                                      {team.sponsors.map((sponsor, sponsorIndex) => (
                                        <img
                                          key={sponsorIndex}
                                          src={sponsor}
                                          alt="Sponsor"
                                          className="sponsor-logo"
                                        />
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                ))}
  
                <div className="club-socials">
                  <h1>Seuran sosiaalinen media</h1>
                  <div className="club-socials-icons">
                    {club.socialLinks.map((link, index) => (
                      <a
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link"
                      >
                        <div className="social-icon">{link.icon}</div>
                        <p>{link.handle}</p>
                      </a>
                    ))}
                  </div>
                </div>
  
                {club.sponsors && club.sponsors.length > 0 && (
                  <div className="club-sponsors">
                    <h1>Seuran sponsorit</h1>
                    <div className="club-sponsors-slide">
                      {club.sponsors.map((sponsor, sponsorIndex) => (
                        <img
                          key={sponsorIndex}
                          src={sponsor}
                          alt="Sponsor"
                          className="sponsor-logo"
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default Contacts;