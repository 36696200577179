import React from 'react';
import './App.css';
import Header from './components/Header';
import Info from './components/Info';
import Contacts from './components/Contacts';
import Footer from './components/Footer';
import Sponsors from './components/Sponsors';

function App() {
  return (
    <div className="App">
      <Header />
      <Info />
      <Contacts />
      <Sponsors />
      <Footer />
    </div>
  );
}

export default App;
