import React from 'react';
import './Footer.css';
import tinkerit from '../images/tinkerit.png';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-left">
          <h3>Jyväskylän Seudun Palloseura</h3>
          <div className="tinkerit">
            <p>Website by <a href="https://tinkerit.fi"><strong>TinkerIT</strong></a></p>
            <img src={tinkerit} alt="TinkerIT" className="tinkerit-logo" />
          </div>
        </div>
        <div className="footer-right">
          <address>
            <strong>Viitaniemen Liikuntapuisto</strong><br />
            Kisakatu 18<br />
            40100 Jyväskylä<br />
          </address>
          <div className="footer-pdf">
            <a href="/JPS-laskutustiedot.pdf"><strong>Jyväskylän Seudun Palloseura Ry laskutustiedot</strong></a>
            <a href="/JPS-junorit-laskutustiedot.pdf"><strong>JPS Juniorit Ry laskutustiedot</strong></a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;