import React, { useEffect } from "react";
import "./Sponsors.css";
import Ajlaskenta from "../images/sponsors/ajlaskenta.png";
import Okauto from "../images/sponsors/okauto.png";
import Alba from "../images/sponsors/alba.png";
import Ommellinen from "../images/sponsors/ommellinen.webp";
import Block from "../images/sponsors/block.png";
import P2v from "../images/sponsors/p2v.png";
import Clever from "../images/sponsors/clever.png";
import Phrak from "../images/sponsors/phrak.png";
import Consti from "../images/sponsors/consti.png";
import Pihlavamaki from "../images/sponsors/pihlavamaki.png";
import Eversheds from "../images/sponsors/eversheds.png";
import Pynnonen from "../images/sponsors/pynnonen.png";
import Fennia from "../images/sponsors/fennia.png";
import RakennuspalveluAssamaki from "../images/sponsors/rakennuspalvelu_assamaki.png";
import Geocontrol from "../images/sponsors/geocontrol.png";
import Rjr from "../images/sponsors/rjr.png";
import Gradia from "../images/sponsors/gradia.png";
import RTV from "../images/sponsors/rtv.png";
import Hamalainen from "../images/sponsors/hamalainen.webp";
import SaintGobain from "../images/sponsors/saintgobain.png";
import Insinooriavain from "../images/sponsors/insinooriavain.png";
import Seiska from "../images/sponsors/seiska.png";
import Jty from "../images/sponsors/jty.png";
import Semma from "../images/sponsors/semma.webp";
import Jyvaskyla from "../images/sponsors/jyvaskyla.png";
import Staari from "../images/sponsors/staari.png";
import Kemell from "../images/sponsors/kemell.png";
import Stark from "../images/sponsors/stark.png";
import Klp from "../images/sponsors/klp.webp";
import Telinekataja from "../images/sponsors/telinekataja.png";
import Kopioteam from "../images/sponsors/kopioteam.png";
import Toshiba from "../images/sponsors/toshiba.png";
import Ksml from "../images/sponsors/ksml.png";
import Uki from "../images/sponsors/uki.webp";
import Lahitapiola from "../images/sponsors/lahitapiola.png";
import Urheiluakatemia from "../images/sponsors/urheiluakatemia.png";
import Loistokoti from "../images/sponsors/loistokoti.png";
import Vuorimies from "../images/sponsors/vuorimies.png";
import Makinen from "../images/sponsors/makinen.png";
import Wpop from "../images/sponsors/wpop.png";

const sponsors1 = [
  { id: 1, src: Ajlaskenta, alt: "AJ Laskenta" },
  { id: 2, src: Okauto, alt: "OK Auto" },
  { id: 3, src: Alba, alt: "Alba" },
  { id: 4, src: Ommellinen, alt: "Ommellinen" },
  { id: 5, src: Block, alt: "Block" },
  { id: 6, src: P2v, alt: "P2V" },
  { id: 7, src: Clever, alt: "Clever" },
  { id: 8, src: Phrak, alt: "PH Rakennuttajapalvelu" },
];

const sponsors2 = [
  { id: 9, src: Consti, alt: "Consti" },
  { id: 10, src: Pihlavamaki, alt: "Pihlavamäki" },
  { id: 11, src: Eversheds, alt: "Eversheds" },
  { id: 12, src: Pynnonen, alt: "Pynnönen" },
  { id: 13, src: Fennia, alt: "Fennia" },
  { id: 14, src: RakennuspalveluAssamaki, alt: "Rakennuspalvelu Assamäki" },
  { id: 15, src: Geocontrol, alt: "Geocontrol" },
  { id: 16, src: Rjr, alt: "RJR" },
];

const sponsors3 = [
  { id: 17, src: Gradia, alt: "Gradia" },
  { id: 18, src: RTV, alt: "RTV" },
  { id: 19, src: Hamalainen, alt: "Hämäläinen" },
  { id: 20, src: SaintGobain, alt: "Saint Gobain" },
  { id: 21, src: Insinooriavain, alt: "Insinööriavain" },
  { id: 22, src: Seiska, alt: "Seiska" },
  { id: 23, src: Jty, alt: "JTY" },
  { id: 24, src: Semma, alt: "Semma" },
];

const sponsors4 = [
  { id: 25, src: Jyvaskyla, alt: "Jyväskylä" },
  { id: 26, src: Staari, alt: "Staari" },
  { id: 27, src: Kemell, alt: "Kemell" },
  { id: 28, src: Stark, alt: "Stark" },
  { id: 29, src: Klp, alt: "KLP" },
  { id: 30, src: Telinekataja, alt: "Telinekataja" },
  { id: 31, src: Kopioteam, alt: "Kopioteam" },
  { id: 32, src: Toshiba, alt: "Toshiba" },
];

const sponsors5 = [
  { id: 33, src: Ksml, alt: "KSML" },
  { id: 34, src: Uki, alt: "UKI" },
  { id: 35, src: Lahitapiola, alt: "LähiTapiola" },
  { id: 36, src: Urheiluakatemia, alt: "Urheiluakatemia" },
  { id: 37, src: Loistokoti, alt: "Loistokoti" },
  { id: 38, src: Vuorimies, alt: "Vuorimies" },
  { id: 39, src: Makinen, alt: "Mäkinen" },
  { id: 40, src: Wpop, alt: "WPOP" },
];


const Sponsors = () => {
    useEffect(() => {
      const slides = document.querySelectorAll(".logos-slide");
      slides.forEach((slide) => {
        const copy = slide.cloneNode(true);
        slide.parentNode.appendChild(copy);
      });
    }, []);
  
    return (
      <div className="sponsors-container">
        <h2>YHTEISTYÖKUMPPANIT</h2>
        <div className="logos">
          <div className="logos-row logos1-row">
            <div className="logos-slide logos1-slide">
              {sponsors1.map((sponsor) => (
                <img key={sponsor.id} src={sponsor.src} alt={sponsor.alt} className="sponsor-logo" />
              ))}
            </div>
          </div>
  
          <div className="logos-row logos2-row">
            <div className="logos-slide logos2-slide">
              {sponsors2.map((sponsor) => (
                <img key={sponsor.id} src={sponsor.src} alt={sponsor.alt} className="sponsor-logo" />
              ))}
            </div>
          </div>
  
          <div className="logos-row logos3-row">
            <div className="logos-slide logos3-slide">
              {sponsors3.map((sponsor) => (
                <img key={sponsor.id} src={sponsor.src} alt={sponsor.alt} className="sponsor-logo" />
              ))}
            </div>
          </div>

          <div className="logos-row logos4-row">
            <div className="logos-slide logos4-slide">
              {sponsors4.map((sponsor) => (
                <img key={sponsor.id} src={sponsor.src} alt={sponsor.alt} className="sponsor-logo" />
              ))}
            </div>
          </div>

          <div className="logos-row logos5-row">
            <div className="logos-slide logos5-slide">
              {sponsors5.map((sponsor) => (
                <img key={sponsor.id} src={sponsor.src} alt={sponsor.alt} className="sponsor-logo" />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Sponsors;